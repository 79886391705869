import clsx from 'clsx';

import { getTotalItemsCount } from '@/stores/shoppingCart/shoppingCart';

export function CartBadge(props: React.HTMLAttributes<HTMLSpanElement>) {
  const itemCount = getTotalItemsCount();
  if (itemCount === 0) return null;

  return <span className={clsx(props.className)} {...props} />;
}
